<template>
  <div class="gashapon-record-container">
    <p class="gashapon-record-title">抽奖记录</p>
    <el-table
      v-if="lotteryListInfo.length"
      class="lottery-list-table"
      :data="lotteryListInfo"
    >
      <el-table-column
        fixed="left"
        label="序号"
        width="50px"
        type="index"
        align="center"
      ></el-table-column>
      <el-table-column
        label="奖项"
        width="70px"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.itemName }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="奖品"
        align="center"
      >
        <template slot-scope="scope">
          <div class="reword-details">
            <p v-if="scope.row.drawNum">{{scope.row.drawNum + '次抽奖次数'}}</p>
            <p v-if="scope.row.assetsName">{{ scope.row.assetsName }}</p>
            <p v-if="scope.row.integral"> {{ scope.row.integral + '积分' }}</p>
            <p v-if="scope.row.couponName">{{ scope.row.couponName }}</p>
            <p v-if="!scope.row.drawNum && !scope.row.assetsName && !scope.row.integral && !scope.row.couponName">--</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        width="90px"
        fixed="right"
        label="时间"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.createTime.substring(5,7) + '.' + scope.row.createTime.substring(8,13) + '.' + scope.row.createTime.substring(14,16)}}</span>
        </template>
      </el-table-column>
    </el-table>
    <div
      v-else
      class="col-center"
      style="margin-top: 30vh;"
    >
      <img
        style="width:6.5rem;height:auto;margin-bottom:1.375rem;"
        src="../../assets/icon/no-list.png"
      />
      <p style="font-size: 16px;font-family: lantingheiweight;font-weight: 500;color: #666666;line-height: 22px;">暂无数据</p>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import api from '../../api/index-client'
import { Table,TableColumn } from 'element-ui';
Vue.use(Table)
Vue.use(TableColumn)

export default {
  data () {
    return {
      lotteryListInfo: [],
    }
  },
  mounted () {
    this.getUserRecord()
  },
  methods: {
    getUserRecord () {
      api
        .get('lottery/getUserRecord?lotteryId=' + this.$route.query.lotteryId + '&page=0&size=65535&sort=create_time,desc')
        .then(result => {
          if (result.data.success) {
            console.log(result);
            this.lotteryListInfo = result.data.data.content
            this.$store.commit('HIDE_APPLOADING')
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../../assets/icon/toast-error.png'),
            });
          }
        })
    }
  },
}
</script>
<style lang="less" scoped>
.gashapon-record-container {
  width: 100vw;
  min-height: 100vh;
  position: absolute;
  top: 0;
  background: #fffaf4;
  .lottery-list-table {
    margin: 40px 0;
    width: 94%;
    margin-left: 3%;
    background: #fce3e1;
    border-radius: 10px;
    border-bottom: 7px solid #fce3e1;
    border-top: 13px solid #fce3e1;
    border-left: 9px solid #fce3e1;
    border-right: 9px solid #fce3e1;
    /deep/ tr,
    /deep/ th {
      background-color: #ffffff;
      border-bottom: 5px solid #fce3e1;
      border-right: 5px solid #fce3e1;
    }
    /deep/ thead {
      background-color: #fce3e1;
    }
    /deep/ th:last-child,
    /deep/ tr:last-child,
    /deep/ td:last-child {
      border-right: 0px solid #fce3e1;
    }
    /deep/ tr:first-child {
      border-radius: 10px 10px 0 0;
    }
    /deep/th {
      font-size: 14px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #050505;
      line-height: 20px;
    }
    /deep/ td {
      border-bottom: 5px solid #fce3e1;
      border-right: 5px solid #fce3e1;
      font-size: 14px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #050505;
      line-height: 20px;
    }
  }
  /deep/.lottery-list-table::before {
    display: none;
  }
  /deep/.el-table__fixed-right::before,
  /deep/.el-table__fixed::before {
    display: none;
  }
  .gashapon-record-title {
    width: 100%;
    text-align: center;
    font-size: 1.125rem;
    font-weight: 900;
    color: #1c1c1c;
    line-height: 1.5625rem;
    padding-top: 1.5rem;
  }
}
/deep/.el-table .cell {
  padding: 0 !important;
}
.reword-details {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
</style>